import React from "react";
import Layout from "../components/layout";
import { GatsbySeo } from 'gatsby-plugin-next-seo';

const pageNotFound = () => {
  return (
    <Layout>
      <GatsbySeo 
        title="404"
      />
      <div className="container">
        <h1>Page not found</h1>
      </div>
    </Layout>
  );
};

export default pageNotFound;
